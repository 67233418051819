/**
 * @constant
 * @type {'deposit'}
 */
const DEPOSIT_TRANSACTION_TYPE = 'deposit'

/**
 * @constant
 * @type {'withdrawal'}
 */
const WITHDRAWAL_TRANSACTION_TYPE = 'withdrawal'

/**
 * Available transaction types
 * @readonly
 * @enum {('deposit'|'withdrawal')}
 */
export const BriteTransactionType = {
  DEPOSIT: DEPOSIT_TRANSACTION_TYPE,
  WITHDRAWAL: WITHDRAWAL_TRANSACTION_TYPE,
}

/**
 * Available machine states
 * @readonly
 * @enum {('brite_client_failed'|'completed'|'creating_order'|'failed'|'initializing'|'verification'|'starting_brite'|'waiting'|'transfer')}
 */
export const BriteState = {
  BRITE_CLIENT_FAILED: 'brite_client_failed',
  COMPLETED: 'completed',
  CREATING_ORDER: 'creating_order',
  FAILED: 'failed',
  INITIALIZING: 'initializing',
  VERIFICATION: 'verification',
  STARTING_BRITE: 'starting_brite',
  TRANSFER: 'transfer',
  WAITING: 'waiting',
}
