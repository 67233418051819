import * as Constants from './constants'
import * as R from 'ramda'

export const states = {
  [Constants.BriteState.BRITE_CLIENT_FAILED]: {
    fail: Constants.BriteState.FAILED,
  },

  [Constants.BriteState.COMPLETED]: {
    reset: [Constants.BriteState.INITIALIZING],
  },

  [Constants.BriteState.CREATING_ORDER]: {
    brite_client_fail: Constants.BriteState.BRITE_CLIENT_FAILED,
    complete: Constants.BriteState.COMPLETED,
    fail: Constants.BriteState.FAILED,
    reset: Constants.BriteState.INITIALIZING,
    start_brite: Constants.BriteState.STARTING_BRITE,
  },

  [Constants.BriteState.FAILED]: {
    reset: Constants.BriteState.INITIALIZING,
  },

  [Constants.BriteState.INITIALIZING]: {
    create_order: Constants.BriteState.CREATING_ORDER,
    fail: Constants.BriteState.FAILED,
  },

  [Constants.BriteState.VERIFICATION]: {
    brite_client_fail: Constants.BriteState.BRITE_CLIENT_FAILED,
    complete: Constants.BriteState.COMPLETED,
    fail: Constants.BriteState.FAILED,
    reset: Constants.BriteState.INITIALIZING,
    transfer: Constants.BriteState.TRANSFER,
  },

  [Constants.BriteState.STARTING_BRITE]: {
    fail: Constants.BriteState.FAILED,
    reset: Constants.BriteState.INITIALIZING,
    wait: Constants.BriteState.WAITING,
  },

  [Constants.BriteState.TRANSFER]: {
    brite_client_fail: Constants.BriteState.BRITE_CLIENT_FAILED,
    complete: Constants.BriteState.COMPLETED,
    fail: Constants.BriteState.FAILED,
    reset: Constants.BriteState.INITIALIZING,
  },

  [Constants.BriteState.WAITING]: {
    brite_client_fail: Constants.BriteState.BRITE_CLIENT_FAILED,
    fail: Constants.BriteState.FAILED,
    reset: Constants.BriteState.INITIALIZING,
    transfer: Constants.BriteState.TRANSFER,
    verify: Constants.BriteState.VERIFICATION,
  },
}

/**
 * @constant
 */
export const MACHINE_TRANSITIONED = 'MACHINE_TRANSITIONED'

/**
 * @constant
 */
export const MACHINE_ASSIGNED = 'MACHINE_ASSIGNED'

/**
 * @param {string} transition State machine event
 * @param {Object} payload Event payload
 */
export function transition(transition, payload) {
  return {
    type: MACHINE_TRANSITIONED,
    payload: [transition, payload || {}],
  }
}

/**
 * @param {Object} payload State machine context
 */
export function assign(payload) {
  return {
    type: MACHINE_ASSIGNED,
    payload,
  }
}

export function reducer(state, action) {
  if (action.error) {
    return state
  }

  switch (action.type) {
    case MACHINE_ASSIGNED: {
      return R.assocPath(
        ['context'],
        R.mergeDeepRight(state.context, action.payload),
        state
      )
    }

    case MACHINE_TRANSITIONED: {
      const [type, payload] = action.payload
      const transitions = states[state.value] || {}
      const nextState = transitions[type]

      if (state.type === type) {
        return R.mergeDeepRight(state, {
          payload: payload,
        })
      }

      if (nextState == null) {
        return state
      }

      return R.mergeDeepRight(state, {
        payload: payload,
        value: nextState,
        type,
      })
    }

    default: {
      return state
    }
  }
}
