import * as Constants from './constants'
import * as Machine from './reducer'
import * as R from 'ramda'

import {Api} from './api'
import React from 'react'
import kebabCase from 'lodash.kebabcase'

const IFRAME_ID = 'brite-payment-system'

const INITIAL_STATE = {
  context: {
    sessionToken: null,
    token: null,
  },
  payload: {},
  value: 'initializing',
}

function Iframe(props) {
  return <div id={IFRAME_ID} {...props} />
}

function getIframeStyles(style = {}) {
  return R.filter(Boolean, {
    background_color: style.backgroundColor,
    button_text_color: style.buttonTextColor,
    font_family: style.fontFamily,
    primary_color: style.primaryColor,
    selected_color: style.selectedColor,
    text_strong_color: style.textStrongColor,
    text_weak_color: style.textWeakColor,
  })
}

function getIframeConfig(options) {
  return R.reduce(
    (config, option) => {
      if (R.not(R.isNil(options[option]))) {
        if (option === 'style') {
          return R.assoc('style', getIframeStyles(options.style), config)
        } else {
          return R.assoc(option, options[option], config)
        }
      } else {
        return config
      }
    },
    {selector: `#${IFRAME_ID}`},
    R.keys(options)
  )
}

function getIsAwaitingInput(machineState, amountCents) {
  if (amountCents) {
    return R.contains(machineState, [
      Constants.BriteState.FAILED,
      Constants.BriteState.VERIFICATION,
      Constants.BriteState.WAITING,
    ])
  } else {
    return R.contains(machineState, [
      Constants.BriteState.FAILED,
      Constants.BriteState.WAITING,
    ])
  }
}

function getMessageText(message, reason) {
  switch (message) {
    case 'player.account-locked': {
      return `player.account-locked.${kebabCase(reason)}`
    }

    default: {
      return kebabCase(message)
    }
  }
}

function getMessageType(errorCode) {
  switch (errorCode) {
    case 'locked': {
      return 'info'
    }

    default: {
      return 'error'
    }
  }
}

function transformErrors(errors) {
  return R.map(
    (error) => ({
      messageText: getMessageText(
        error.message || error.error_code,
        error.reason
      ),
      messageType: getMessageType(error.error_code),
      variables: R.omit(['message', 'show_notification'], error),
    }),
    R.filter(R.propEq('showNotification', true), errors)
  )
}

/**
 * @typedef {string} HexColor Hex color, e.g. '#ffe4e1'
 */

/**
 * @typedef {Object} BriteIframeStyles Styles for customize the iframe UI
 * @property {HexColor} [backgroundColor] Background color
 * @property {HexColor} [buttonTextColor] Button text color
 * @property {string} [fontFamily] Web safe fonts, e.g. 'Verdena'
 * @property {HexColor} [primaryColor] Primary color
 * @property {HexColor} [selectedColor] Selected color
 * @property {HexColor} [textStrongColor] Text strong color
 * @property {HexColor} [textWeakColor] Text weak color
 */

/**
 * @typedef {Object} BriteResult
 * @property {boolean} isAwaitingInput True if awaiting user input false otherwise
 * @property {ReactComponent} Iframe Brite iframe
 * @property {string} status State machine status
 */

/**
 * Provides four flows; authentication, authentication with deposit,
 * deposit with session, and withdrawal with session.
 * @param {Object} params
 * @param {string} params.affiliateClickId Affiliate click ID
 * @param {string} params.amountCents Amount in cents
 * @param {string} params.apiUrl API URL
 * @param {string} params.brandKey Brand key
 * @param {string} params.clientType Client type
 * @param {string} params.countryCode Country code
 * @param {string} params.depositOfferId Deposit offer ID
 * @param {number|string} [params.height] Brite iframe height
 * @param {string} params.language Language
 * @param {string} params.btag NetRefer BTag
 * @param {BriteIframeStyles} [params.styles] Brite iframe styles
 * @param {string} params.sessionToken Session token
 * @param {'deposit'|'withdrawal'} params.transactionType Transaction type
 * @param {string} params.utmCampaign UTM campaign
 * @param {string} params.utmMedium UTM medium
 * @param {string} params.utmSource UTM source
 * @param {number|string} [params.width] Brite iframe width
 * @param {Function} params.onFailure Failure callback
 * @param {Function} params.onSuccess Success callback
 * @returns {BriteResult}
 */
export function useBrite({
  affiliateClickId,
  amountCents,
  apiUrl,
  brandKey,
  btag,
  clientType,
  countryCode,
  depositOfferId,
  seonSession,
  height,
  language,
  redirectUrl,
  sessionToken,
  styles,
  transactionType,
  utmCampaign,
  utmMedium,
  utmSource,
  width,
  onSuccess,
  onFailure,
}) {
  const api = React.useMemo(() => new Api(apiUrl), [apiUrl])
  const [attempts, setAttempts] = React.useState(10)
  const [state, dispatch] = React.useReducer(Machine.reducer, INITIAL_STATE)
  const client = React.useMemo(() => {
    if (state.context.token) {
      return new window.Brite(state.context.token)
    }
  }, [state.context.token])

  React.useEffect(() => {
    if (!client && state.value === Constants.BriteState.INITIALIZING) {
      const timerId = window.setInterval(() => {
        if (typeof window.Brite === 'function') {
          dispatch(Machine.transition('create_order'))
        }
      }, 250)
      return () => {
        clearInterval(timerId)
      }
    }
  }, [client, state.value])

  React.useEffect(() => {
    if (state.value === Constants.BriteState.CREATING_ORDER) {
      api
        .authorization({
          affiliateClickId,
          amountCents,
          brandKey,
          btag,
          clientType,
          countryCode,
          depositOfferId,
          seonSession,
          language,
          redirectUrl,
          sessionToken,
          transactionType,
          utmCampaign,
          utmMedium,
          utmSource,
        })
        .then((data) => {
          if (transactionType === Constants.BriteTransactionType.WITHDRAWAL) {
            if (data.status === 'pending') {
              dispatch(Machine.transition('start_brite'))
              dispatch(Machine.assign({token: data.token}))
            } else {
              dispatch(Machine.transition('complete'))
            }
          } else {
            dispatch(Machine.transition('start_brite'))
            dispatch(Machine.assign({token: data.token}))
          }
        })
        .catch((errors) => {
          dispatch(Machine.transition('fail', {errors}))
        })
    }
  }, [
    affiliateClickId,
    amountCents,
    brandKey,
    btag,
    clientType,
    countryCode,
    depositOfferId,
    seonSession,
    language,
    redirectUrl,
    sessionToken,
    state.value,
    transactionType,
    utmCampaign,
    utmMedium,
    utmSource,
  ])

  React.useEffect(() => {
    let timerId = null

    function checkStatus() {
      api
        .verification(
          R.mergeDeepRight(
            {language, sessionToken},
            {token: state.context.token}
          )
        )
        .then((data) => {
          if (data.status === 'completed') {
            setAttempts(10)
            dispatch(Machine.assign({sessionToken: data.sessionToken}))
            if (!amountCents) {
              dispatch(Machine.transition('complete'))
            }
          } else {
            if (attempts < 0) {
              dispatch(Machine.transition('fail'))
            } else {
              setAttempts((attempts) => attempts - 1)
              timerId = window.setTimeout(checkStatus, 500)
            }
          }
        })
        .catch((errors) => {
          setAttempts(10)
          dispatch(Machine.transition('fail', {errors}))
        })
    }

    if (
      state.context.token &&
      state.value === Constants.BriteState.VERIFICATION
    ) {
      checkStatus()
      return () => {
        window.clearInterval(timerId)
      }
    }
  }, [
    attempts,
    amountCents,
    language,
    sessionToken,
    state.context.token,
    state.value,
  ])

  React.useEffect(() => {
    if (state.context.token && state.value === Constants.BriteState.TRANSFER) {
      api
        .transfer({
          sessionToken: sessionToken || state.context.sessionToken,
          token: state.context.token,
        })
        .then(() => {
          dispatch(Machine.transition('complete'))
        })
        .catch((errors) => {
          dispatch(Machine.transition('fail', {errors}))
        })
    }
  }, [
    sessionToken,
    state.context.sessionToken,
    state.context.token,
    state.value,
  ])

  React.useEffect(() => {
    if (state.value === Constants.BriteState.BRITE_CLIENT_FAILED) {
      api
        .sessions({
          sessionToken: sessionToken || state.context.sessionToken,
          token: state.context.token,
        })
        .then((errors) => {
          dispatch(
            Machine.transition('fail', {
              errors,
            })
          )
        })
        .catch((errors) => {
          if (process.env.NODE_ENV !== 'production') {
            // Pass real error to console for easier debugging
            // eslint-disable-next-line no-console
            console.error(errors)
          }
        })

      if (sessionToken || state.context.sessionToken) {
        api
          .cancel({
            sessionToken: sessionToken || state.context.sessionToken,
            token: state.context.token,
          })
          .then(() => {
            dispatch(Machine.transition('fail'))
          })
          .catch((errors) => {
            if (process.env.NODE_ENV !== 'production') {
              // Pass real error to console for easier debugging
              // eslint-disable-next-line no-console
              console.error(errors)
            }
          })
      }
    }
  }, [
    sessionToken,
    state.context.sessionToken,
    state.context.token,
    state.value,
  ])

  React.useEffect(() => {
    // If client has been initialised and the machine state is not starting_brite
    // then functionality relying on dynamic data needs to be called outside the
    // brite client callbacks, to avoid being called with stale payload.
    if (client && state.value === Constants.BriteState.STARTING_BRITE) {
      client.start(
        getIframeConfig({height, style: styles, width}),
        (briteState) => {
          switch (briteState) {
            case window.Brite.STATE_AUTHENTICATION_COMPLETED: {
              dispatch(Machine.transition('verify'))

              break
            }

            case window.Brite.STATE_COMPLETED: {
              if (amountCents) {
                dispatch(Machine.transition('transfer'))
                client.stop()
              }

              dispatch(Machine.transition('verify'))
              client.stop()

              break
            }

            case window.Brite.STATE_FAILED:
            case window.Brite.STATE_CANCELLED: {
              dispatch(Machine.transition('brite_client_fail'))
              break
            }

            default: {
              break
            }
          }
        },
        () => client.stop()
      )
    }
  }, [amountCents, client, height, state.value, styles, width])

  React.useEffect(() => {
    if (state.value === Constants.BriteState.COMPLETED) {
      onSuccess(state.context.sessionToken)
    }
  }, [onSuccess, state.value, state.context.sessionToken])

  React.useEffect(() => {
    if (state.value === Constants.BriteState.FAILED) {
      if (Array.isArray(state.payload.errors)) {
        onFailure({errors: transformErrors(state.payload.errors)})
      } else {
        onFailure({errors: []})
      }
    }
  }, [onFailure, state.value, state.payload.errors])

  React.useEffect(() => {
    if (
      state.value === Constants.BriteState.FAILED &&
      state.context.sessionToken &&
      !sessionToken
    ) {
      onSuccess(state.context.sessionToken)
    }
  }, [onSuccess, sessionToken, state.context.sessionToken, state.value])

  React.useEffect(() => {
    const PRODUCTION_ORIGIN = 'https://abonea-249014.appspot.com'
    const STAGING_ORIGIN = 'https://abonea-176213.appspot.com'
    const ALLOWED_ORIGINS = [PRODUCTION_ORIGIN, STAGING_ORIGIN]

    function handleIframeInitialized(event) {
      if (R.contains(event.origin, ALLOWED_ORIGINS)) {
        if (R.path(['event'], JSON.parse(event.data)) === 'load') {
          dispatch(Machine.transition('wait'))
        }
      }
    }

    window.addEventListener('message', handleIframeInitialized)

    return () => window.removeEventListener('message', handleIframeInitialized)
  }, [])

  React.useEffect(() => {
    if (state.value === Constants.BriteState.INITIALIZING) {
      dispatch(Machine.assign(INITIAL_STATE.context))
    }
  }, [state.value, sessionToken])

  React.useEffect(() => {
    return () => {
      dispatch(Machine.assign(INITIAL_STATE.context))
    }
  }, [])

  // This hook makes sure that 'cancel' is called and don't
  // leave any transaction pending on the backend, if e.g.
  // user exit the flow in the middle of an transaction
  // this hook will cancel that transaction.
  React.useEffect(() => {
    return () => {
      if (sessionToken || state.context.sessionToken) {
        if (state.context.token) {
          api
            .cancel({
              sessionToken: sessionToken || state.context.sessionToken,
              token: state.context.token,
            })
            .catch((errors) => {
              if (process.env.NODE_ENV !== 'production') {
                // Pass real error to console for easier debugging
                // eslint-disable-next-line no-console
                console.error(errors)
              }
            })
        }
      }
    }
  }, [sessionToken, state.context.sessionToken, state.context.token])

  const onReset = React.useCallback(() => {
    if (client) {
      client.stop()
    }
    dispatch(Machine.transition('reset'))
  }, [client])

  return {
    Iframe,
    isAwaitingInput: getIsAwaitingInput(state.value, amountCents),
    onReset,
    status: state.value,
    token: state.context.token,
  }
}
