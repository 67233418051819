import * as R from 'ramda'

import URL from 'url'

/**
 * Finite state machine (FSM) definition
 * @constant
 */
export const states = {
  bankid: {
    fail: 'failed',
    redirect: 'completed',
    start_payment: 'starting_payment',
    wait: 'pending',
  },
  completed: {},
  failed: {
    reset: 'pending',
  },
  pending: {
    ask_to_open_bank_id_app: 'bankid',
    ask_to_open_swish_app: 'swish',
    fail: 'failed',
    redirect: 'completed',
    redirect_to_bank_id_app: 'bankid',
    redirect_to_swish_app: 'swish',
    start_payment: 'starting_payment',
  },
  starting_payment: {
    ask_to_open_swish_app: 'swish',
    fail: 'failed',
    redirect_to_swish_app: 'swish',
  },
  swish: {
    fail: 'failed',
    redirect: 'completed',
  },
}

/**
 * @constant
 */
export const CREATED = 'CREATED'

/**
 * @constant
 */
export const MESSAGE_CHANGED = 'MESSAGE_CHANGED'

/**
 * @constant
 */
export const PHONE_NUMBER_CHANGED = 'PHONE_NUMBER_CHANGED'

/**
 * @constant
 */
export const MACHINE_TRANSITIONED = 'MACHINE_TRANSITIONED'

/**
 * @param {string} id
 * @returns {Object} Redux action
 */
export function createOrder(id) {
  return {
    type: CREATED,
    payload: id,
  }
}

/**
 * @param {string} transition State machine event
 * @param {Object} payload Event payload
 */
export function transitionMachine(transition, payload) {
  return {
    type: MACHINE_TRANSITIONED,
    payload: [transition, payload || {}],
  }
}

/**
 * @param {string} phoneNumber
 */
export function changePhoneNumber(phoneNumber) {
  return {
    type: PHONE_NUMBER_CHANGED,
    payload: phoneNumber,
  }
}

export function reducer(state, action) {
  if (action.error) {
    return state
  }

  switch (action.type) {
    case CREATED: {
      return Object.assign({}, state, {id: action.payload})
    }

    case MACHINE_TRANSITIONED: {
      const [type, payload] = action.payload
      const transitions = states[state.machineState] || {}
      const nextState = transitions[type]

      if (nextState == null) {
        return state
      }

      return Object.assign({}, state, {
        machinePayload: payload,
        machineState: nextState,
      })
    }

    case PHONE_NUMBER_CHANGED: {
      return Object.assign({}, state, {phoneNumber: action.payload})
    }

    default: {
      return state
    }
  }
}

function injectOrderId(url, orderId, lastState) {
  const urlObject = typeof url === 'string' ? URL.parse(url, true) : url
  return R.pipe(
    R.dissoc('search'),
    R.assocPath(['query', 'last_state'], lastState),
    R.assocPath(['query', 'order_id'], orderId),
    URL.format
  )(urlObject)
}

function getBankIdRedirectUrl(callbackUrl, orderId, token) {
  if (!callbackUrl || !orderId || !token) {
    return null
  }

  const isIOS =
    Boolean(navigator.platform) && /iPad|iPhone|iPod/.test(navigator.platform)
  const isChromeIOS = /CriOS/.test(navigator.userAgent)

  const url = {
    protocol: 'bankid:',
    pathname: '/',
    slashes: true,
    query: {
      autostarttoken: token,
      redirect: injectOrderId(callbackUrl, orderId, 'bankid'),
    },
  }

  if (isIOS && isChromeIOS) {
    // Use HTTPS protocol for Chrome as iOS allows deeplinking with custom
    // protocols only in Safari
    url.protocol = 'https:'

    // Use appropriate hostname for deeplinking
    // Source: https://www.bankid.com/en/utvecklare/guider/teknisk-integrationsguide/programstart
    url.hostname = 'app.bankid.com'

    const callbackUrlObject = URL.parse(callbackUrl, true)
    callbackUrlObject.protocol = 'googlechrome:'
    url.query.redirect = injectOrderId(callbackUrlObject, orderId, 'bankid')
  }

  return URL.format(url)
}

function getSwishRedirectUrl(callbackUrl, orderId, token) {
  if (!callbackUrl || !orderId || !token) {
    return null
  }

  const isIOS =
    Boolean(navigator.platform) && /iPad|iPhone|iPod/.test(navigator.platform)
  const isChromeIOS = /CriOS/.test(navigator.userAgent)

  const url = {
    protocol: 'swish:',
    pathname: 'paymentrequest',
    slashes: true,
    query: {
      token,
      callbackurl: injectOrderId(callbackUrl, orderId, 'swish'),
    },
  }

  if (isIOS && isChromeIOS) {
    const callbackUrlObject = URL.parse(callbackUrl, true)
    callbackUrlObject.protocol = 'googlechrome:'
    url.query.callbackurl = injectOrderId(callbackUrlObject, orderId, 'swish')
  }

  // Replace proper slashes with whatever Swish desires
  return R.replace('///', '//', URL.format(url))
}

/**
 * Get relevant redirect URL if possible
 * @param {Object} state
 * @param {Object} props
 * @param {string} props.callbackUrl URL to return to from opened app
 * @param {string} props.lastState Last known FSM state
 */
export function getRedirectUrl(state, props) {
  if (state.machineState === props.lastState) {
    return null
  }

  switch (state.machineState) {
    case 'bankid': {
      return getBankIdRedirectUrl(
        props.callbackUrl,
        state.id,
        state.machinePayload.auto_start_token
      )
    }
    case 'swish': {
      return getSwishRedirectUrl(
        props.callbackUrl,
        state.id,
        state.machinePayload.token
      )
    }
    default: {
      return null
    }
  }
}
