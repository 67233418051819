/**
 * Order methods:
 *   - "local" for devices which have BankID app installed;
 *   - "remote_personal_number" for devices without BankID app, requires personal number;
 *   - "remote_qr_code" for devices without BankID app, requires scanning QR code.
 * @readonly
 * @enum {string}
 */
export const ORDER_METHODS = {
  local: 'local',
  remotePersonalNumber: 'remote_personal_number',
  remoteQrCode: 'remote_qr_code',
}

/**
 * Order types
 * @readonly
 * @enum {string}
 */
export const ORDER_TYPES = {
  deposit: 'deposit',
  signin: 'signin',
}
