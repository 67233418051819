import * as R from 'ramda'
import * as Utils from '../utils/index'

import {ORDER_METHODS, ORDER_TYPES} from './constants'

/**
 * Perform request and convert response to JSON
 * @param {string} url
 * @param {Object} _params Fetch parameters
 * @returns {Object} Parsed JSON response
 */
function fetchJson(url, _params) {
  const params = R.assocPath(['headers', 'Accept'], 'application/json', _params)
  return fetch(url, params)
    .then(Utils.Http.checkStatus)
    .then((res) => res.json())
}

/**
 * Remove redundant parameters from order request body
 * @param {*} order
 */
function prepareOrderParams(order) {
  if (order.order_type !== ORDER_TYPES.deposit) {
    return R.omit(['amount_cents', 'currency', 'payment_method'], order)
  }

  return order
}

/**
 * Base class for API client.
 * Covers validation and errors.
 * @interface
 */
class BaseApi {
  createOrder(params) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log('[API]', 'createOrder()')
    }

    if (typeof params.appId !== 'string') {
      throw new TypeError(`"appId" must be a String`)
    }

    if (!Object.values(ORDER_METHODS).includes(params.orderMethod)) {
      const methods = Object.values(ORDER_METHODS).join(', ')
      throw new TypeError(`"orderMethod" must be one of: ${methods}`)
    }

    if (params.orderMethod === ORDER_METHODS.remotePersonalNumber) {
      if (params.personalNumber == null) {
        throw new TypeError(
          `"personalNumber" is required for "${params.orderMethod}"`
        )
      }
    }

    if (params.orderType === ORDER_TYPES.deposit) {
      if (params.amountCents == null) {
        throw new TypeError(
          `"amountCents" is required for "${params.orderType}"`
        )
      }

      if (params.currency == null) {
        throw new TypeError(`"currency" is required for "${params.orderType}"`)
      }

      if (params.paymentMethod == null) {
        throw new TypeError(
          `"paymentMethod" is required for "${params.orderType}"`
        )
      }
    }
  }

  startPayment(orderId, orderMethod, phoneNumber) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log('[API]', 'startPayment()')
    }

    if (!orderId) {
      throw new TypeError(`"orderId" is required`)
    }

    if (orderMethod !== ORDER_METHODS.local && !phoneNumber) {
      throw new TypeError(`"phoneNumber" is required for "${orderMethod}"`)
    }
  }

  status(orderId) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log('[API]', 'status()')
    }

    if (!orderId) {
      throw new TypeError(`"orderId" is required`)
    }
  }
}

export class Api extends BaseApi {
  constructor(apiUrl) {
    super()
    if (!apiUrl) {
      throw new Error('Missing API URL')
    }
    this.apiUrl = apiUrl
  }

  createOrder(orderParams) {
    super.createOrder(orderParams)
    // @TODO: Fix order creation loop
    // Workaround which allows to create only one order per API client instance
    if (this.result) {
      return this.result
    }
    const url = `${this.apiUrl}/orders`
    const params = {
      headers: {'Content-Type': 'application/json'},
      method: 'POST',
      body: JSON.stringify({
        order: prepareOrderParams({
          app_id: orderParams.appId,
          amount_cents: orderParams.amountCents,
          bank_id_type: orderParams.orderMethod,
          currency: orderParams.currency,
          language: orderParams.language,
          order_type: orderParams.orderType,
          payment_method: orderParams.paymentMethod,
          personal_number: orderParams.personalNumber,
          extra_attributes: {
            affiliate_click_id: orderParams.affiliateClickId,
            brand_key: orderParams.brand,
            client_type: orderParams.clientType,
            deposit_offer_package_id: orderParams.depositOfferId,
            language: orderParams.language,
            netreferer_btag: orderParams.btag,
            session_token: orderParams.sessionToken,
            utm_campaign: orderParams.utmCampaign,
            utm_medium: orderParams.utmMedium,
            utm_source: orderParams.utmSource,
          },
        }),
      }),
    }
    this.result = fetchJson(url, params).then((body) => {
      if (body.success) {
        return body.result
      } else {
        return Promise.reject(body.errors)
      }
    })
    return this.result
  }
  startPayment(orderId, orderMethod, phoneNumber) {
    super.startPayment(orderId, orderMethod, phoneNumber)
    const url = `${this.apiUrl}/orders/${orderId}/swish_payment`
    const params = {
      headers: {'Content-Type': 'application/json'},
      method: 'POST',
      body: JSON.stringify({
        swish_payment: {
          payment_type:
            orderMethod === ORDER_METHODS.local ? 'local' : 'remote',
          phone_number: phoneNumber,
        },
      }),
    }
    return fetchJson(url, params).then((body) => {
      if (body.success) {
        return body.result
      } else {
        return Promise.reject(body.errors)
      }
    })
  }
  status(orderId) {
    super.status(orderId)
    const url = `${this.apiUrl}/orders/${orderId}/status`
    const params = {
      method: 'GET',
    }
    return fetchJson(url, params).then((body) => {
      if (body.success) {
        const action =
          body.result.status === 'failed' ? 'fail' : body.result.action
        return R.assoc('action', action, body.result)
      } else {
        return Promise.reject(body.errors)
      }
    })
  }
}
