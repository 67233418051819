import * as Constants from './constants'
import * as Utils from '../utils/index'

const AUTHORIZATION_ENDPOINT_MAPPINGS = {
  [Constants.BriteTransactionType.DEPOSIT]:
    Constants.BriteTransactionType.DEPOSIT,
  [Constants.BriteTransactionType.WITHDRAWAL]: 'withdrawals',
}

/**
 * Perform request and convert response to JSON
 * @param {string} url
 * @param {Object} params Fetch parameters
 * @returns {Object} Parsed JSON response
 */
function fetchJson(url, params) {
  return fetch(url, params)
    .then(Utils.Http.checkStatus)
    .then((res) => res.json())
}

/**
 * @param {Object} response
 * @returns {Array} list of errors
 */
function transformErrorResponse(response) {
  if (Array.isArray(response.errors)) {
    return response.errors
  } else {
    return [response]
  }
}

/**
 * Base class for API client.
 * Covers validation and errors.
 * @interface
 */
class BaseApi {
  authorization(params) {
    if (!params.countryCode) {
      throw new Error('"countryCode" is required')
    }

    if (typeof params.countryCode !== 'string') {
      throw new TypeError(`"countryCode" must be a String`)
    }
  }

  cancel(params) {
    if (!params.token) {
      throw new Error('"token" is required')
    }

    if (typeof params.token !== 'string') {
      throw new TypeError(`"token" must be a String`)
    }
  }

  verification(params) {
    if (!params.token) {
      throw new Error('"token" is required')
    }

    if (typeof params.token !== 'string') {
      throw new TypeError(`"token" must be a String`)
    }

    if (!params.language) {
      throw new Error('"language" is required')
    }

    if (typeof params.language !== 'string') {
      throw new TypeError(`"language" must be a String`)
    }
  }

  sessions(params) {
    if (!params.token) {
      throw new Error('"token" is required')
    }

    if (typeof params.token !== 'string') {
      throw new TypeError(`"token" must be a String`)
    }
  }

  transfer(params) {
    if (!params.token) {
      throw new Error('"token" is required')
    }

    if (typeof params.token !== 'string') {
      throw new TypeError(`"token" must be a String`)
    }
  }
}

export class Api extends BaseApi {
  constructor(apiUrl) {
    super()
    if (!apiUrl) {
      throw new Error('Missing API URL')
    }

    this.apiUrl = apiUrl
  }

  authorization(data) {
    super.authorization(data)

    if (data.amountCents) {
      const url = `${this.apiUrl}/brite/${
        AUTHORIZATION_ENDPOINT_MAPPINGS[data.transactionType]
      }`
      const params = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': `application/vnd.casinosaga.v1, application/json`,
          'Authorization': data.sessionToken ? data.sessionToken : '',
        },
        method: 'POST',
        body: JSON.stringify({
          amount: data.amountCents / 100,
          country_code: data.countryCode,
          attributes: {
            affiliate_click_id: data.affiliateClickId,
            brand_key: data.brandKey,
            client_type: data.clientType,
            deposit_offer_package_id: data.depositOfferId,
            seon_session: data.seonSession,
            language: data.language,
            netreferer_btag: data.btag,
            redirect_url: data.redirectUrl,
            utm_campaign: data.utmCampaign,
            utm_medium: data.utmMedium,
            utm_source: data.utmSource,
          },
        }),
      }
      return fetchJson(url, params).then((body) => {
        if (body.success) {
          return body.result
        } else {
          return Promise.reject(transformErrorResponse(body))
        }
      })
    } else {
      const url = `${this.apiUrl}/brite/authorization`
      const params = {
        headers: {
          'Accept': 'application/vnd.casinosaga.v1, application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          country_code: data.countryCode,
          attributes: {
            affiliate_click_id: data.affiliateClickId,
            brand_key: data.brandKey,
            client_type: data.clientType,
            language: data.language,
            netreferer_btag: data.btag,
            redirect_url: data.redirectUrl,
            utm_campaign: data.utmCampaign,
            utm_medium: data.utmMedium,
            utm_source: data.utmSource,
          },
        }),
      }
      return fetchJson(url, params).then((body) => {
        if (body.success) {
          return body.result
        } else {
          return Promise.reject(transformErrorResponse(body))
        }
      })
    }
  }

  verification(data) {
    super.verification(data)
    const url = `${this.apiUrl}/brite/verification`
    const params = {
      headers: {
        'Accept': `application/vnd.casinosaga.v1, application/json`,
        'Authorization': data.sessionToken ? data.sessionToken : '',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        token: data.token,
        attributes: {
          language: data.language,
        },
      }),
    }
    return fetchJson(url, params).then((body) => {
      if (!body.errors && !body.error) {
        return body.result
      } else {
        return Promise.reject(transformErrorResponse(body))
      }
    })
  }

  sessions(data) {
    super.sessions(data)
    const url = `${this.apiUrl}/brite/sessions/${data.token}`
    const params = {
      headers: {
        'Accept': `application/vnd.casinosaga.v1, application/json`,
        'Authorization': data.sessionToken ? data.sessionToken : '',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
    return fetchJson(url, params).then((body) => {
      if (body.success) {
        return transformErrorResponse(body.result)
      } else {
        return Promise.reject(transformErrorResponse(body))
      }
    })
  }

  transfer(data) {
    super.transfer(data)
    const url = `${this.apiUrl}/brite/transfer`
    const params = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': `application/vnd.casinosaga.v1, application/json`,
        'Authorization': data.sessionToken ? data.sessionToken : '',
      },
      method: 'POST',
      body: JSON.stringify({
        token: data.token,
      }),
    }
    return fetchJson(url, params).then((body) => {
      if (body.success) {
        return body.result
      } else {
        return Promise.reject(transformErrorResponse(body))
      }
    })
  }

  cancel(data) {
    super.cancel(data)
    const url = `${this.apiUrl}/brite/cancel`
    const params = {
      headers: {
        'Accept': `application/vnd.casinosaga.v1, application/json`,
        'Authorization': data.sessionToken ? data.sessionToken : '',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        token: data.token,
      }),
    }
    return fetchJson(url, params).then((body) => {
      if (body.success) {
        return body.result
      } else {
        return Promise.reject(transformErrorResponse(body))
      }
    })
  }
}
