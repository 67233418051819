import * as R from 'ramda'

import PropTypes from 'prop-types'
import format from 'date-fns/format'
import {useEffect} from 'react'

const TRUSTLY_SIGNUP_CALLBACK_EVENT_TYPE = '@rushplay/sign-up/trustly/callback'

function toError({errMsg, error_description}) {
  if (errMsg) {
    if (errMsg === 'ERR_DECLINED_LIMIT_OVERDRAWN') {
      return {
        message: 'err_declined_limit_overdrawn',
      }
    } else {
      const formattedErrMsg = errMsg.replace(/( )/g, '-')
      try {
        const error = R.path(['errors', 'base', 0], JSON.parse(errMsg))
        const errorCode = R.pathOr('general', ['errorCode'], error)
        const errorReason = R.pathOr('unknown', ['reason'], error)

        const variables = {
          limitPeriod: R.pathOr('', ['limitPeriod'], error),
          limitValue: R.pathOr('', ['limitValue'], error) / 6000,
          expiresAt: format(R.pathOr('', ['expiresAt'], error), 'DD.MM.YYYY'),
        }

        return {
          message: R.toLower(`errors.${errorCode}.${errorReason}`),
          variables,
        }
      } catch (e) {
        return {
          message: R.toLower(`errors.${formattedErrMsg}`),
        }
      }
    }
  } else if (error_description) {
    return {
      message: R.toLower(`errors.${error_description}`),
    }
  }
}

export function SignUpObserver(props) {
  function handleResponse(error, sessionId) {
    if (props.onSuccess && sessionId) {
      props.onSuccess(sessionId)
    }

    if (error) {
      if (error.message === 'errors.cancelled-transaction') {
        props.onCancel()
      } else {
        props.onError(error)
      }
    }
  }

  const query = R.pathOr({}, ['query'], props)
  useEffect(() => {
    function handleMessage(event) {
      const eventType = R.path(['data', 'type'], event)

      if (eventType == TRUSTLY_SIGNUP_CALLBACK_EVENT_TYPE) {
        const payload = R.pathOr({}, ['data', 'payload'], event)
        handleResponse(payload.error, payload.sessionId)
      }
    }

    const error = toError({
      errMsg: query.errMsg,
      error_description: query.error_description,
    })

    if (props.query) {
      handleResponse(error, query.sessionId)
    }

    if (props.origin) {
      window[props.target].postMessage(
        {
          type: TRUSTLY_SIGNUP_CALLBACK_EVENT_TYPE,
          payload: {
            error,
            sessionId: query.sessionId,
          },
        },
        props.origin
      )
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [query.errMsg, query.error_description, query.sessionId, props.target])

  return null
}

SignUpObserver.defaultProps = {
  target: 'top',
  onCancel: () => {},
  onError: () => {},
}

SignUpObserver.propTypes = {
  origin: PropTypes.string,
  query: PropTypes.object,
  target: PropTypes.oneOf(['top', 'parent']),
  onCancel: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
}
