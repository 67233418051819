import * as R from 'ramda'

import React, {useEffect, useState} from 'react'

import PropTypes from 'prop-types'
import URL from 'url'
import fetch from 'isomorphic-fetch'

/**
 * Inject `NotifyParent` query param to enable Trustly sending post message
 * when BankID must be opened by player.
 * @param {string} redirectUrl
 */
function enableBankIdPostMessage(redirectUrl) {
  const redirectUrlObj = URL.parse(redirectUrl, true)
  redirectUrlObj.query.NotifyParent = 1
  delete redirectUrlObj.search
  return URL.format(redirectUrlObj)
}

export function Iframe(props) {
  const [redirectOutput, setRedirectOutput] = useState()
  const [statusUrl, setStatusUrl] = useState()

  const urlQuery = React.useMemo(
    () => ({
      // Required parameters
      'client_id': props.clientId,
      'identity_provider': props.identityProvider,
      'redirect_uri': props.successRedirectUrl,

      // Optional parameters
      'amount': props.amount,
      'country': props.countryCode,
      'currency': props.currency,
      'iframe': true,
      'locale': `${props.language}_${props.countryCode.toUpperCase()}`,
      'response_type': 'code',
      'scope': 'default',
      'ssn': props.ssn,
      'attributes[affiliate_click_id]': props.clickId,
      'attributes[brand_key]': props.brand,
      'attributes[client_type]': props.clientType,
      'attributes[failure_url]': props.failureRedirectUrl,
      'attributes[deposit_offer_package_id]': props.depositOfferId,
      'attributes[seon_session]': props.seonSession,
      'attributes[language]': props.language,
      'attributes[netreferer_btag]': props.btag,
      'attributes[session_token]': props.sessionToken,
      'attributes[utm_campaign]': props.utmCampaign,
      'attributes[utm_medium]': props.utmMedium,
      'attributes[utm_source]': props.utmSource,
      'attributes[verification_token]': props.verificationToken,
    }),
    [
      props.clientId,
      props.successRedirectUrl,
      props.amount,
      props.countryCode,
      props.currency,
      props.seonSession,
      props.language,
      props.countryCode,
      props.ssn,
      props.clickId,
      props.brand,
      props.clientType,
      props.depositOfferId,
      props.language,
      props.btag,
      props.sessionToken,
      props.utmCampaign,
      props.utmMedium,
      props.utmSource,
      props.verificationToken,
    ]
  )

  useEffect(() => {
    if (props.host) {
      const parsedUrl = URL.parse(props.host)
      const url = URL.format({
        host: parsedUrl.host,
        pathname: `${parsedUrl.path}/oauth/authorize`,
        protocol: parsedUrl.protocol,
        query: urlQuery,
      })

      let cancelled = false

      fetch(url)
        .then((res) => {
          if (res.status === 200) {
            return res.json()
          }

          throw new Error('error.fetch-failed')
        })
        .then((body) => {
          if (!cancelled) {
            if (body.success) {
              const url = enableBankIdPostMessage(body.redirectOutput.url)
              setStatusUrl(body.links.statusUrl)
              setRedirectOutput(
                props.notifyParent
                  ? R.assoc('url', url, body.redirectOutput)
                  : body.redirectOutput
              )
            } else {
              throw new Error(body.errors[0])
            }
          }
        })
        .catch((error) => {
          if (!cancelled && props.onError) {
            props.onError(error)
          }
        })

      return () => {
        cancelled = true
      }
    }
  }, [props.notifyParent, props.onError, setRedirectOutput, urlQuery])

  useEffect(() => {
    let attempts = 5
    let id

    function checkStatus(url) {
      fetch(url)
        .then((response) => response.json())
        .then((body) => {
          props.onStatusUpdate(body)
          attempts = 5
          id = window.setTimeout(() => checkStatus(url), 1000)
        })
        .catch(() => {
          if (attempts > 0) {
            attempts = attempts - 1
            id = window.setTimeout(() => checkStatus(url), 1000)
          }
        })
    }

    if (props.onStatusUpdate && statusUrl) {
      checkStatus(statusUrl)
      return () => window.clearTimeout(id)
    }
  }, [statusUrl, props.onStatusUpdate])

  if (redirectOutput && redirectOutput.container === 'iframe') {
    return (
      <iframe
        className={props.className}
        src={redirectOutput.url}
        style={{width: props.width, height: props.height}}
        title={props.title}
      />
    )
  }

  return null
}

Iframe.defaultProps = {
  btag: '',
  children: null,
  clickId: '',
  depositOfferId: '',
  seonSession: '',
  height: '100%',
  sessionToken: '',
  utmCampaign: '',
  utmMedium: '',
  utmSource: '',
  width: '100%',
}

Iframe.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  brand: PropTypes.string.isRequired,
  btag: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  clickId: PropTypes.string,
  clientId: PropTypes.string.isRequired,
  clientType: PropTypes.oneOf(['browser', 'ios', 'mobile-browser', 'unknown'])
    .isRequired,
  countryCode: PropTypes.string.isRequired,
  currency: PropTypes.string,
  depositOfferId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  failureRedirectUrl: PropTypes.string,
  seonSession: PropTypes.string,
  height: PropTypes.string.isRequired,
  host: PropTypes.string,
  identityProvider: PropTypes.oneOf(['brite', 'trustly', 'zimpler']).isRequired,
  language: PropTypes.string.isRequired,
  notifyParent: PropTypes.bool,
  sessionToken: PropTypes.string,
  ssn: PropTypes.string,
  successRedirectUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  utmCampaign: PropTypes.string,
  utmMedium: PropTypes.string,
  utmSource: PropTypes.string,
  verificationToken: PropTypes.string,
  width: PropTypes.string.isRequired,
  onError: PropTypes.func,
  onStatusUpdate: PropTypes.func,
}
