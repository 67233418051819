/**
 * Throw error if fetch response status is not 2xx
 * @param {Object} response
 * @returns {Object} Response
 */
export function checkStatus(response) {
  if (response.status < 200 && response.status >= 300) {
    const error = new Error('Fetch failed')
    error.code = response.status
    throw error
  }

  return response
}
