import {ORDER_METHODS, ORDER_TYPES} from './constants'

import PropTypes from 'prop-types'
import React from 'react'
import {states} from './reducer'
import {useOrder} from './use-order'

/**
 * Pröjs integration component. Uses render prop pattern.
 * @see OrderRenderProps
 */
// `React.memo` helps to avoid hook effects to be called more than necessary
export const ProjsSignUp = React.memo((props) =>
  props.children(useOrder(props))
)

ProjsSignUp.defaultProps = {
  onFailure: () => {},
  onRedirect: () => {},
  onStateChange: () => {},
  onSuccess: () => {},
}

// Because ESLint
ProjsSignUp.displayName = 'ProjsSignUp'

ProjsSignUp.propTypes = {
  /**
   * NetRefer affiliate click ID
   */
  affiliateClickId: PropTypes.string,

  /**
   * Deposit amount in cents
   */
  amountCents: PropTypes.number,

  /**
   * Authenticating application ID
   * @example "314d5b02-6425-45d3-82b3-3d4af1e41b1b"
   */
  appId: PropTypes.string.isRequired,

  /**
   * Brand code
   */
  brand: PropTypes.oneOf(['casinoheroes', 'speedy', 'speedybet']).isRequired,

  /**
   * NetRefer BTag
   * @example "654421_44B727F95F044AD482A0E3A2B3398401"
   */
  btag: PropTypes.string,

  /**
   * Children as a function
   */
  children: PropTypes.func.isRequired,

  /**
   * Deposit currency code in ISO 4217 format
   */
  currency: PropTypes.string,

  /**
   * Authenticating client type
   */
  clientType: PropTypes.oneOf(['browser', 'ios', 'mobile-browser', 'unknown'])
    .isRequired,

  /**
   * Deposit offer package ID to claim
   */
  depositOfferId: PropTypes.string,

  /**
   * Language to use by authentification app
   */
  language: PropTypes.string,

  /**
   * Last known machine state
   */
  lastState: PropTypes.oneOf(Object.keys(states)),

  /**
   * Existing order ID (used to continue existing flow)
   */
  orderId: PropTypes.string,

  /**
   * Order method
   * @see ORDER_METHODS
   */
  orderMethod: PropTypes.oneOf(Object.values(ORDER_METHODS)),

  /**
   * Desired action type
   */
  orderType: PropTypes.oneOf(Object.values(ORDER_TYPES)).isRequired,

  /**
   * Payment method used for authentication
   */
  paymentMethod: PropTypes.oneOf(['swish']),

  /**
   * Personal number to use for authentication
   */
  personalNumber: PropTypes.string,

  /**
   * Callback URL (used to continue local flow after redirects to apps)
   */
  url: PropTypes.string.isRequired,

  /**
   * Specific product promotion or strategic campaign identifier
   */
  utmCampaign: PropTypes.string,

  /**
   * Link type (e.g. cost per click or email) identifier
   */
  utmMedium: PropTypes.string,

  /**
   * Traffic source identifier
   */
  utmSource: PropTypes.string,

  /**
   * Callback to be executed on flow failure
   */
  onFailure: PropTypes.func.isRequired,

  /**
   * Callback performing redirect to given URL
   * @param {string} url
   */
  onRedirect: PropTypes.func.isRequired,

  /**
   * Callback performing redirect to given URL
   * @param {string} prevState Previous state machine state
   * @param {string} state Current state machine state
   * @param {Object.<string,*>} context Current state machine context
   */
  onStateChange: PropTypes.func.isRequired,

  /**
   * Callback to be executed on flow success
   * @param {string} token Session token
   */
  onSuccess: PropTypes.func.isRequired,
}
